<template>
    <div>
        <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false">
            <md-content>
                <div class="dialog-header">
                    <div class="dialog-title">Điều kiện lọc</div>
                </div>
                <div class="dialog-content">
                    <div class="page-list-body">
                        <div class="form-body" style="padding-top: 20px;">
                            <div class="row">
                                <div class="col l-12 m-12 c-12">
                                    <div class="form-control">
                                        <md-autocomplete v-model="vehicleName" @md-selected="getVehicleSelected" :md-options="vehicles" @md-changed="getVehicles"  @md-opened="getVehicles" :class="{'md-invalid': submitted && $v.search.licensePlate.$error }">
                                            <label>Phương tiện</label>
                                            <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                                <md-highlight-text :md-term="term">{{ item.licensePlate }}</md-highlight-text>
                                            </template>
                                            <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                                "{{ term }}" Không tìm thấy!
                                            </template>
                                            <span class="md-error" v-if="submitted && !$v.search.licensePlate.isSelected">Vui lòng chọn phương tiện</span>
                                        </md-autocomplete>
                                        <md-button @click="openVehicle()" class="md-icon-button">
                                            <md-icon>manage_search</md-icon>
                                        </md-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </md-content>
            <md-dialog-actions>
                <md-button class="md-primary" @click="submit()">Chọn</md-button>
                <md-button class="md-primary" @click="showDialog = false">Đóng</md-button>
            </md-dialog-actions>
        </md-dialog>
        <vehicleList ref="vehicleList" @close="closeVehicle" />
    </div>
</template>

<script>
    import messageBox from '../../../utils/messageBox'; 
    import { required } from 'vuelidate/lib/validators';
    import vehicleList from '../../../components/popup/_VehicleList.vue';
    import vehicleService from '../../../api/vehicleService';

    export default {
        components: {
            vehicleList
        },
        data: () => ({
            showDialog: false,
            loadding: false, 
            search: { licensePlate: '' },
            submitted: false,
            vehicleName: '',
            vehicles: [],
        }),

        created() {
        },

        methods: { 
            //Vehicle
            closeVehicle(item){
                this.vehicleName = item.licensePlate;
                this.search.licensePlate = item.licensePlate;
                this.$refs.vehicleList.close();
            },

            getVehicleSelected(val){
                this.vehicleName = val.licensePlate;
                this.search.licensePlate = val.licensePlate;
            },

            openVehicle(){
                this.$refs.vehicleList.open();
            },

            getVehicles(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, licensePlate: searchTerm };
                vehicleService.getVehicles(search).then((response) => {
                    if(response.statusCode == 200){
                        this.vehicles = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            submit(){
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                this.$emit('close', this.search);
                this.showDialog = false;
            },

            open(){
                this.showDialog = true;
            },

            close(){
                this.showDialog = false;
            },
        },
        watch: { 
            vehicleName: function (val) { 
                if(val == ''){
                    this.search.licensePlate = '';
                }
            }
        },
        validations: {
            search: {
                licensePlate: { required },
            }
        }
    }
</script>

<style lang="css" scoped>
    .md-content {
        padding: 10px;
        width: 500px;
        height: 180px;
        max-width: 500px;
        max-height: 180px;
    }
    .dialog-content {
        height: 180px;
    }
</style>