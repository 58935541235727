<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>list_alt</md-icon> <span>Báo cáo tổng hợp đăng kiểm phương tiện</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action"> 
                        <md-button v-shortkey="['ctrl', 'm']" @shortkey="add()" @click="openSearch()" class="md-raised md-primary btn-add">Tìm <span>k</span>iếm<md-tooltip>Tìm kiếm (Ctrl + T)</md-tooltip></md-button>
                        <md-button v-shortkey="['ctrl', 'r']" @shortkey="refresh()" @click="refresh()" class="md-icon-button md-dense md-raised md-primary">
                            <md-icon>cached</md-icon>
                            <md-tooltip>Làm mới (Ctrl + R)</md-tooltip>
                        </md-button>
                    </div>
                </div>
            </div>
            <div class="row no-gutters">
                <div class="col l-6 m-6 c-12">
                    <div class="report-info">
                        <h4>TỔNG HỢP ĐĂNG KIỂM PHƯƠNG TIỆN - {{vehicle.name}} - {{vehicle.licensePlate}}</h4>
                        <div class="sum-repair">
                            <label>Tổng các đợt đăng kiểm: </label>
                            <span>{{formatNumber(calSum())}} vnđ</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="page-list-body">
                <div class="table-content">
                    <md-content class="md-scrollbar">
                        <table class="data-table" style="width: 1430px !important;">
                            <thead>
                                <tr>
                                    <th style="width:80px;">#</th>
                                    <th style="width:450px;">Chi phí</th>
                                    <th style="width:200px;">Số tiền</th>
                                    <th style="width:200px;">Vat</th>
                                    <th style="width:200px;">Thành tiền</th>
                                    <th style="width:200px;">Người thực hiện</th>
                                    <th style="width:150px;">Ngày thực hiện</th>
                                </tr>
                            </thead>
                            <tbody v-if="loadding == true" style="height: 150px;">
                                <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                            </tbody>
                            <tbody v-if="loadding == false && data.length == 0" style="height: 150px;">
                                <tr><td colspan="100" class="no-data"><span>Không có dữ liệu</span></td></tr>
                            </tbody>
                            <tbody v-for="(obj, index) in data" :key="obj.id" :class="{ 'odd': index % 2 !== 0 }">
                                <tr>
                                    <td class="center" style="font-weight:bold;">{{romanize(index + 1)}}</td>
                                    <td style="backgroundColor:#82CD47; font-weight:bold;" colspan="4">{{'Dự toán ' + obj.invoiceCode + '/' + obj.invoiceDate}}</td>
                                    <td class="center" style="font-weight:bold;">{{obj.staff}}</td>
                                    <td class="center">{{ obj.invoiceDate }}</td>
                                </tr>
                                <tr v-for="(item, index) in obj.details" :key="item.id" :class="{ 'odd': index % 2 !== 0 }">
                                    <td class="center">{{index + 1}}</td>
                                    <td>{{item.feeName}}</td>
                                    <td class="right">{{formatNumber(item.amount)}}</td>
                                    <td class="right">{{formatNumber(item.vat)}}%</td>
                                    <td class="right">{{formatNumber(item.pay)}}</td>
                                    <td class="center"></td>
                                    <td class="center"></td>
                                </tr>
                                <tr>
                                    <td class="center"></td>
                                    <td style="font-weight:bold;" colspan="3">TỔNG TIỀN</td>
                                    <td class="right" style="font-weight:bold;">{{formatNumber(obj.amount)}}</td>
                                    <td class="center"></td>
                                    <td class="center"></td>
                                </tr>
                                <tr>
                                    <td class="center"></td>
                                    <td style="font-weight:bold;" colspan="3">THUẾ VAT</td>
                                    <td class="right" style="font-weight:bold;">{{formatNumber(obj.vat)}}%</td>
                                    <td class="center"></td>
                                    <td class="center"></td>
                                </tr>
                                <tr>
                                    <td class="center"></td>
                                    <td style="font-weight:bold;" colspan="3">TỔNG CỘNG</td>
                                    <td class="right" style="font-weight:bold;">{{formatNumber(obj.pay)}}</td>
                                    <td class="center"></td>
                                    <td class="center"></td>
                                </tr>
                            </tbody>
                        </table>
                    </md-content>
                </div>
            </div>
        </div>
        <searchForm ref="searchForm" @close="closeSearch" />
    </div>
</template>
<script>
    import reportRegistrationService from '../../../api/reportRegistrationService';
    import vehicleService from '../../../api/vehicleService';
    import messageBox from '../../../utils/messageBox';
    import { mapActions } from 'vuex';
    import searchForm from './_Search.vue';

    export default {
        components: {
            searchForm
        },
        metaInfo: {
            title: 'Báo cáo tổng hợp đăng kiểm phương tiện'
        },
        data() {
            return {
               loadding: false,
               search: { licensePlate: '' },
               data: [],
               id: 0,
               vehicle: {}
            }
        },
        created(){
            
        },
        mounted() {
            this.search.licensePlate = this.$route.query.id;
            if(this.search.licensePlate != '' && this.search.licensePlate != undefined){
                this.getData();
            }
            else{
                this.openSearch();
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            calSum(){
                let sum = 0;
                for (var i = 0; i < this.data.length; i++) {
                    sum = sum + this.data[i].pay;
                }
                return sum;
            },

            openSearch(){
                this.$refs.searchForm.open();
            },

            closeSearch(search){
                this.search = search;
                this.$router.push('/report-registration-vehicle?id=' + search.licensePlate).catch(()=>{});
                this.getData();
            },

            refresh(){
                this.getData();
            },

            getData(){
                this.data = [];
                this.loadding = true;
                reportRegistrationService.getReportVehicle(this.search).then((response) => {
                    if(response.statusCode == 200){
                        this.data = response.data;
                        this.getVehicle();
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.loadding = false; });
            },

            getVehicle(){
                vehicleService.getVehicleByLicensePlate(this.search.licensePlate).then((response) => {
                    if(response.statusCode == 200){
                        this.vehicle = response.data[0];
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.loadding = false; });
            },
        },
        watch: {
            'search.pageIndex': function () {
                this.getData();
            },
            'search.pageSize': function () {
                this.search.pageIndex = 1;
                this.getData();
            }
        }
    }

</script>

<style scoped>
    .report-info {
        padding: 15px;
        display: flex;
        flex-direction: column;
    }
    .report-info h4{
        font-weight: bold;
        text-transform: uppercase;
    }
    .sum-repair {
        display: flex;
        padding-top: 15px;
        align-items: center;
    }
    .sum-repair label {
        color: red;
        font-weight: bold;
        font-size: 16px;
        padding-right: 10px;
    }
    .sum-repair span {
        color: green;
        font-weight: bold;
        font-size: 16px;
    }
</style>